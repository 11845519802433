import { render, staticRenderFns } from "./SubscribePaymentPriceBlock.vue?vue&type=template&id=f9dbb448&scoped=true"
import script from "./SubscribePaymentPriceBlock.vue?vue&type=script&lang=js"
export * from "./SubscribePaymentPriceBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9dbb448",
  null
  
)

export default component.exports