<template>
  <div class="reward-activity-info-block">
    <p class="card-title">扣款金額設定</p>
    <el-form v-show="formData.periods" ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="扣款方式" prop="method">
        <el-select v-model="formData.method" placeholder="請選擇" clearable :disabled="!!formData.orderCount">
          <el-option v-for="item in subscribePaymentTypeConfig" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="每次扣款金額" prop="amount">
        <el-input v-model="formData.amount" placeholder="請輸入" :disabled="!!formData.orderCount">
          <template #suffix> 元</template>
        </el-input>
      </el-form-item>
      <p class="text-gray-80 text-sub">單次訂閱總額： <span class="text-primary-100">{{ showTotal }}</span> 元 (共 {{ showDuration }} 期)</p>
    </el-form>
    <p v-show="!formData.periods" class="text-gray-80 text-sub">請先輸入「單次訂閱期數」</p>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed, set } from 'vue'
import { noEmptyRules, integerRules } from '@/validation'
import { subscribePaymentTypeConfig } from '@/config/subscribe'
import { get } from 'lodash'

export default defineComponent({
  name: 'SubscribeDurationBlock',
  components: { },
  props: ['FormsContext', 'planData'],
  setup (props) {
    const formRef = ref(null)
    const formData = reactive({
      method: null,
      amount: null,
      periods: null,
      orderCount: null,
    })
    const formRules = {
      method: [noEmptyRules()],
      amount: [noEmptyRules(), integerRules()],
    }
    const syncData = () => {
      const data = props.planData
      formData.method = get(data, 'installmentSettings.method')
      formData.amount = get(data, 'installmentSettings.fields.amount')
      formData.periods = get(data, 'periodSettings.fields.periods')
      formData.orderCount = get(data, 'orderCount')
    }

    const compactData = computed(() => {
      const data = {
        installmentSettings: {
          method: get(formData, 'method'),
          fields: {
            // amount: get(formData, 'amount'),
            // additionalRules: {
            //   firstTimeSubscriptionOnly: false,
            // },
          },
        },
      }
      if (get(formData, 'method') === 'periods') set(data.installmentSettings.fields, 'amount', Number(get(formData, 'amount')))
      // TODO: P2 manual 要回傳 amounts
      console.log(data)
      return { ...data }
    })
    const showTotal = computed(() => {
      const data = formData.amount
      console.log(data)
      if (Number.isInteger(Number(data)) && Number(data) > 0) {
        return data * formData.periods
      }
      return '-'
    })
    const showDuration = computed(() => {
      const data = formData.periods
      if (Number.isInteger(Number(data)) && Number(data) > 0) {
        return data
      }
      return '-'
    })
    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('paymentPrice', formRef.value)

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('paymentPrice', { ...compactData.value })
    })
    watch(props.planData, (data) => {
      formData.periods = data.periods
    })

    return {
      formRef,
      formData,
      formRules,
      subscribePaymentTypeConfig,
      showTotal,
      showDuration,
    }
  },
})
</script>

<style lang="postcss" scoped>
</style>
