<template>
  <div class="reward-activity-info-block">
    <p class="card-title">品項組合</p>
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="選擇品項組合" prop="name">
        <el-select v-model="formData.equityPackId" placeholder="請選擇" clearable :disabled="!!formData.orderCount">
          <el-option v-for="item in subscribeEquityPackList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <div v-if="formData.equityPackId" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[4px]">
            <div v-for="item in equityPackData" :key="item.id" class="flex text-gray-100 text-sub">
              <span class="font-medium">{{ item.label }}：</span>
              <span class="font-normal">{{ item.value }}</span>
            </div>
            <p class="text-danger text-sm">系統不會自動寄出品項。如會員訂閱此方案，可至「訂閱模組 > 訂閱紀錄」查看每筆訂單</p>
          </div>
        </GrayBlockContainer>
      </div>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { get, find, map, reduce } from 'lodash'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useSubscribe } from '@/use/useSubscribe'

export default defineComponent({
  name: 'SubscribeCombineBlock',
  components: { GrayBlockContainer },
  props: ['FormsContext', 'planData'],
  setup (props) {
    const { getSubscribeEquityPack, subscribeEquityPackList } = useSubscribe()

    const formRef = ref(null)
    const formData = reactive({
      equityPackId: null,
      orderCount: 0,
    })
    const formRules = {}
    const equityPackData = computed(() => {
      const equityPack = find(subscribeEquityPackList.value, { id: formData.equityPackId })
      const itemsName = map(get(equityPack, 'equities'), 'name').join('、')
      const sum = reduce(get(equityPack, 'equities'), (total, equity) => total + get(equity, 'price', 0), 0)
      return [
        { label: '品項內容', value: itemsName },
        { label: '預估售價', value: `${sum} 元` },
      ]
    })

    const syncData = () => {
      const data = props.planData
      formData.equityPackId = get(data, 'planEquityPack.equityPack.id')
      formData.orderCount = get(data, 'orderCount')
    }

    const compactData = computed(() => {
      const data = {
        equityPackId: get(formData, 'equityPackId') || null,
      }
      return { ...data }
    })
    onMounted(async () => {
      await nextTick()
      await getSubscribeEquityPack()
      props.FormsContext.setFormRef('equityPack', formRef.value)

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('equityPack', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      equityPackData,
      subscribeEquityPackList,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content, .previewArea {
  @apply w-[560px]
}
</style>
