import { ref } from 'vue'
import { useShop } from '@/use/shop'
import {
  GetSubscribeEquityPack,
  GetSubscribeEquityPackCount,
  GetSubscribeContract,
  GetSubscribeContractCount,
  GetSubscribePlan,
  GetSubscribePlanCount,
  PreviewContract,
  PreviewEquityPack,
} from '@/api/subscribe'
import { get, concat } from 'lodash'
export const useSubscribe = () => {
  const { shopId } = useShop()
  const subscribeEquityPackList = ref([])
  const subscribeContractList = ref([])
  const subscribePlanList = ref([])
  const getSubscribeEquityPack = async () => {
    try {
      let max = await getSubscribeEquityPackCount()
      max = max.count
      const options = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        sortBy: 'createdAt',
        sortType: 'DESC',
      }
      const [res, err] = await GetSubscribeEquityPack(options)
      if (err) throw new Error(err)
      // console.log(res)
      subscribeEquityPackList.value = res
      while (get(subscribeEquityPackList, 'value.length') < max) {
        options.start += options.limit
        const [res, err] = await GetSubscribeEquityPack(options)
        if (err) throw new Error(err)
        subscribeEquityPackList.value = concat(subscribeEquityPackList.value, res)
      }
    } catch (error) {
      window.$message.error(error)
    }
  }
  const getSubscribeEquityPackCount = async () => {
    try {
      return await GetSubscribeEquityPackCount({ shopId: shopId.value })
    } catch (error) {
      window.$message.error(error)
    }
  }
  const getSubscribeContract = async () => {
    try {
      let max = await getSubscribeContractCount()
      max = max.count
      const options = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        sortBy: 'createdAt',
        sortType: 'DESC',
      }
      const [res, err] = await GetSubscribeContract(options)
      if (err) throw new Error(err)
      // console.log(res)
      subscribeContractList.value = res
      while (get(subscribeContractList, 'value.length') < max) {
        options.start += options.limit
        const [res, err] = await GetSubscribeContract(options)
        if (err) throw new Error(err)
        subscribeContractList.value = concat(subscribeContractList.value, res)
      }
    } catch (error) {
      window.$message.error(error)
    }
  }
  const getSubscribeContractCount = async () => {
    try {
      return await GetSubscribeContractCount({ shopId: shopId.value })
    } catch (error) {
      window.$message.error(error)
    }
  }
  const getSubscribePlan = async () => {
    try {
      let max = await getSubscribePlanCount()
      max = max.count
      const options = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
        sortBy: 'createdAt',
        sortType: 'DESC',
      }
      const [res, err] = await GetSubscribePlan(options)
      if (err) throw new Error(err)
      // console.log(res)
      subscribePlanList.value = res
      while (get(subscribePlanList, 'value.length') < max) {
        options.start += options.limit
        const [res, err] = await GetSubscribePlan(options)
        if (err) throw new Error(err)
        subscribePlanList.value = concat(subscribePlanList.value, res)
      }
    } catch (error) {
      window.$message.error(error)
    }
  }
  const getSubscribePlanCount = async () => {
    try {
      return await GetSubscribePlanCount({ shopId: shopId.value })
    } catch (error) {
      window.$message.error(error)
    }
  }
  const previewContract = async (planId) => {
    try {
      return await PreviewContract({ shopId: shopId.value, id: planId })
    } catch (error) {
      window.$message.error(error)
    }
  }
  const previewEquityPack = async (planId) => {
    try {
      return await PreviewEquityPack({ shopId: shopId.value, id: planId })
    } catch (error) {
      window.$message.error(error)
    }
  }
  return {
    getSubscribeEquityPack,
    subscribeEquityPackList,
    getSubscribeContract,
    subscribeContractList,
    getSubscribePlan,
    subscribePlanList,
    previewContract,
    previewEquityPack,
  }
}
