<template>
  <div class="reward-activity-info-block">
    <p class="card-title">單次訂閱時長</p>
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="期數計算方式" prop="method">
        <el-select v-model="formData.method" placeholder="請選擇" clearable :disabled="!!formData.orderCount" @change="onMethodChange">
          <el-option v-for="item in subscribeDurationTypeConfig" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="['manual'].includes(formData.method)"
        label="每一期的天數"
        prop="days"
      >
        <el-input v-model="formData.days" placeholder="請輸入" :disabled="!!formData.orderCount" @input="updateDuration">
          <template #suffix> 天</template>
        </el-input>
      </el-form-item>

      <el-form-item label="單次訂閱期數" prop="periods">
        <el-input v-model="formData.periods" placeholder="請輸入" :disabled="!!formData.orderCount" @input="updateDuration">
          <template #suffix> {{ formData.method === 'manual' ? '期' : '個月' }}</template>
        </el-input>
      </el-form-item>
      <p class="text-gray-80 text-sub">單次訂閱時長 <span class="text-primary-100">{{ showMonth }}</span> {{ formData.method === 'manual' ? '天' : '個月' }}</p>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { noEmptyRules, integerRules } from '@/validation'
import { subscribeDurationTypeConfig } from '@/config/subscribe'
import { get } from 'lodash'

export default defineComponent({
  name: 'SubscribeDurationBlock',
  components: { },
  props: ['FormsContext', 'planData'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = reactive({
      method: null,
      periods: null,
      days: null,
      orderCount: 0,
    })
    const formRules = {
      method: [noEmptyRules()],
      periods: [noEmptyRules(), integerRules()],
      days: [noEmptyRules(), integerRules()],
    }
    const syncData = () => {
      const data = props.planData
      formData.method = get(data, 'periodSettings.method')
      formData.periods = get(data, 'periodSettings.fields.periods')
      formData.days = get(data, 'periodSettings.fields.days')
      formData.orderCount = get(data, 'orderCount')
    }

    const compactData = computed(() => {
      const data = {
        periodSettings: {
          method: get(formData, 'method'),
          fields: {
            periods: Number(get(formData, 'periods')),
          },
        },
      }

      if (formData.method === 'manual') {
        data.periodSettings.fields.days = Number(get(formData, 'days'))
      }

      return { ...data }
    })
    const showMonth = computed(() => {
      const data = formData.periods
      if (formData.method === 'manual') {
        if (formData.days === '' || formData.days === null) return '-'
        if (formData.periods === '' || formData.periods === null) return '-'
        return formData.days * formData.periods
      } else if (Number.isInteger(Number(data)) && Number(data) > 0) {
        return data
      }
      return '-'
    })
    const onMethodChange = () => {
      formData.periods = null
      formData.days = null
    }
    const updateDuration = () => {
      emit('updateDuration', formData.periods)
    }
    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('periods', formRef.value)

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('periods', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      subscribeDurationTypeConfig,
      showMonth,
      updateDuration,
      onMethodChange,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content, .previewArea {
  @apply w-[560px]
}
</style>
