import { reactive } from 'vue'

export const useEditor = () => {
  const editorOption = reactive({
    modules: {
      toolbar: [
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
      ],
    },
  })

  return { editorOption }
}
