<template>
  <div class="subscribe-combine-edit">
    <PageTitle class="page-title" :title="title" icon="chevron_left" hideBtn @iconClick="$router.push({ name: 'SubscribePlanList' })" />
    <div v-if="loading" v-loading="loading" />
    <template v-if="!loading">
      <SubscribeInfoBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :planData="planData"
      />
      <SubscribeCombineBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :planData="planData"
      />
      <SubscribeDurationBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :planData="planData"
        @updateDuration="updateDuration"
      />
      <SubscribePaymentPriceBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :planData="planData"
      />
      <SubscribePaymentTimeBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :planData="planData"
      />
      <SubscribeContractSettingBlock
        class="card-container form-wrapper"
        :FormsContext="FormsContext"
        :planData="planData"
      />
    </template>

    <PageFixedFooter @cancel="onCancel" @confirm="onSubmit" />
    <BaseDialog
      v-if="modal.warning"
      width="566px"
      title="提醒"
      @confirm="submit"
      @cancel="modal.warning = false"
      @close="modal.warning = false"
    >
      <div class="dialog-content">
        <p class="mb-[20px]">若此訂閱方案產生進行中的訂單（有會員訂閱此方案），則不開放調整「基本設定」以外的設定。</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, set, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import SubscribeInfoBlock from './components/SubscribeInfoBlock.vue'
import SubscribeCombineBlock from './components/SubscribeCombineBlock.vue'
import SubscribeDurationBlock from './components/SubscribeDurationBlock.vue'
import SubscribePaymentPriceBlock from './components/SubscribePaymentPriceBlock.vue'
import SubscribePaymentTimeBlock from './components/SubscribePaymentTimeBlock.vue'
import SubscribeContractSettingBlock from './components/SubscribeContractSettingBlock.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import FormsContext from './formsContext'
import { CreateSubscribePlan, UpdateSubscribePlan, FindSubscribePlan } from '@/api/subscribe'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SubscribePlanEdit',
  components: {
    PageFixedFooter,
    SubscribeInfoBlock,
    SubscribeCombineBlock,
    SubscribeDurationBlock,
    SubscribePaymentPriceBlock,
    SubscribePaymentTimeBlock,
    SubscribeContractSettingBlock,
    PageTitle,
    BaseDialog,
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const subscribePlanId = computed(() => route.params.id)
    const copyId = computed(() => route.query.copyId)
    const title = computed(() => {
      if (route.query.edit) return '編輯訂閱方案'
      return '新增訂閱方案'
    })
    const planData = ref({
      periods: null,
    })
    const modal = reactive({
      warning: false,
    })
    const loading = ref(false)

    const onCancel = () => {
      router.push({
        name: 'SubscribePlanList',
      })
    }
    const updateDuration = (data) => {
      set(planData.value, 'periods', data)
    }

    const findSubscribePlan = async (id = null) => {
      loading.value = true
      const [res, err] = await FindSubscribePlan({
        shopId: shopId.value,
        id: id || subscribePlanId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      planData.value = res
      if (id) {
        planData.value.orderCount = null
      }
    }

    const createSubscribePlan = async (payload) => {
      loading.value = true
      const [, err] = await CreateSubscribePlan({
        shopId: shopId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('新增成功!')
    }

    const updateSubscribePlan = async (payload) => {
      loading.value = true
      const [, err] = await UpdateSubscribePlan({
        shopId: shopId.value,
        id: subscribePlanId.value,
        ...payload,
      })
      if (err) throw err
      window.$message.success('更新成功！')
    }

    const onSubmit = async () => {
      const allPass = await FormsContext.validateAll()
      if (allPass) {
        modal.warning = true
      }
    }
    const submit = async () => {
      const data = FormsContext.composFormData()
      const postBody = {
        ...data,
      }
      console.log(postBody)
      try {
        if (!subscribePlanId.value) await createSubscribePlan(postBody)
        if (subscribePlanId.value) await updateSubscribePlan(postBody)
        router.push({ name: 'SubscribePlanList' })
      } catch (error) {
        window.$message.error(error)
        loading.value = false
      }
    }

    onMounted(() => {
      if (copyId.value) findSubscribePlan(copyId.value)
      if (subscribePlanId.value) findSubscribePlan()
    })

    return {
      onCancel,
      onSubmit,
      loading,
      FormsContext,
      planData,
      title,
      updateDuration,
      modal,
      submit,
    }
  },
})
</script>

<style lang="postcss" scoped>
.subscribe-combine-edit {
  @apply flex flex-col gap-[20px];
}
.page-title {
  @apply mb-0
}
.form-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
