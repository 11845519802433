<template>
  <div class="reward-activity-info-block">
    <p class="card-title">基本資訊</p>
    <!-- <el-button v-if="editMode" type="text" class="editRecordBtn" @click="modal.detail = true">基本設定編輯紀錄</el-button> -->
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item v-if="editMode" label="進行中的訂單數量" prop="orderCount">
        <template slot="label">
          <FormItemTooltipLabel label="進行中的訂單數量">
            如有進行中的訂單，代表有會員訂閱此方案，則不開放調整「基本設定」以外的設定
          </FormItemTooltipLabel>
        </template>
        <p class="text-gray-60 text-sub">{{ formData.orderCount }}</p>
      </el-form-item>
      <el-form-item label="訂閱方案名稱" prop="name">
        <p v-if="formData.orderCount > 0">{{ formData.name }}</p>
        <el-input v-else v-model="formData.name" placeholder="請輸入" />
      </el-form-item>
      <el-form-item label="圖片" prop="image" class="form-relative-label others">
        <UploadButton
          cyUploadBtn="service-img-upload-btn"
          cyUploadedImg="service-img-uploaded"
          :img="formData.image"
          :isAvatar="true"
          type="subscription"
          @change="loadImg"
        />
      </el-form-item>
      <el-form-item label="注意事項" prop="pleaseNote" class="w-[560px]">
        <quillEditor v-model="formData.pleaseNote" :options="editorOption" />
      </el-form-item>
      <el-form-item label="開放會員自行取消訂閱" prop="isAllowMemberCancelAutoRenew">
        <el-switch v-model="formData.isAllowMemberCancelAutoRenew" active-text="開啟" inactive-text="關閉" />
      </el-form-item>
    </el-form>
    <SubscriptionImageCropper
      v-if="modal.imageCropper"
      :image="formData.image"
      :shopId="shopId"
      @uploaded="getImage"
      @close="modal.imageCropper = false"
    />
    <SubscribeInfoEditRecordDetail
      :show="modal.detail"
      @close="modal.detail = false"
      @cancel="modal.detail = false"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useEditor } from '@/use/editor'
import { noEmptyRules } from '@/validation'
import { get } from 'lodash'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import UploadButton from '@/components/Button/UploadButton.vue'
import SubscriptionImageCropper from '@/components/SubscriptionImageCropper.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import SubscribeInfoEditRecordDetail from './SubscribeInfoEditRecordDetail.vue'
import { useShop } from '@/use/shop'
export default defineComponent({
  name: 'SubscribeInfoBlock',
  components: {
    UploadButton,
    SubscriptionImageCropper,
    quillEditor,
    FormItemTooltipLabel,
    SubscribeInfoEditRecordDetail,
  },
  props: ['FormsContext', 'planData'],
  setup (props) {
    const { shopId } = useShop()
    const { editorOption } = useEditor()
    const route = useRoute()
    const editMode = computed(() => route.query.edit)
    const formRef = ref(null)
    const formData = reactive({
      orderCount: 0,
      name: null,
      image: null,
      pleaseNote: null,
      isAllowMemberCancelAutoRenew: false,
    })
    const formRules = {
      name: [noEmptyRules()],
    }
    const modal = reactive({
      imageCropper: false,
      detail: false,
    })
    const syncData = () => {
      const data = props.planData
      formData.orderCount = get(data, 'orderCount')
      formData.name = get(data, 'name')
      formData.image = get(data, 'image')
      formData.pleaseNote = get(data, 'pleaseNote')
      formData.isAllowMemberCancelAutoRenew = get(data, 'isAllowMemberCancelAutoRenew')
    }

    const compactData = computed(() => {
      const data = {
        name: get(formData, 'name'),
        imageId: get(formData, 'image.id'),
        pleaseNote: get(formData, 'pleaseNote') || null,
        isAllowMemberCancelAutoRenew: get(formData, 'isAllowMemberCancelAutoRenew'),
        isAutoRenew: false,
      }
      return { ...data }
    })
    const getImage = (data) => {
      formData.image = data
      modal.imageCropper = false
    }
    const loadImg = (img) => {
      formData.image = img
      modal.imageCropper = true
    }
    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('info', formRef.value)

      if (get(props.planData, 'id')) syncData()
      else formData.pleaseNote = '以信用卡/金融卡支付之本課程(服務)禮券面額或以信用卡/金融卡預先支付本課程(服務)之金額，已經永豐商業銀行提供價金保管服務，並先時存入永豐商業銀行之價金保管專戶，專款專用。保管期間自中華民國○年○月○日(出售日)起至○年○月○日止(至少一年)。'
    })

    watch(formData, () => {
      props.FormsContext.setFormData('info', { ...compactData.value })
    })

    return {
      formRef,
      formData,
      formRules,
      editorOption,
      getImage,
      loadImg,
      modal,
      editMode,
      shopId,
    }
  },
})
</script>

<style lang="postcss" scoped>
.form-relative-label {
  @apply relative;
}

.form-relative-label.others::after {
  content: '(建議上傳尺寸1200x1200)';
  @apply absolute left-[50px] top-[2px] text-sm text-gray-80;
}
.editRecordBtn {
  @apply underline cursor-pointer py-0 absolute right-[32px] top-[24px]
}
</style>
